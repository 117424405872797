<template>
    <header class="mo_curation_header">
        <nav class="mo_curation_header_wrap">
            <ul class="dp_flex h_100per">
                <li class="align_center">
                    <a @click="onClickPre">
                        <img src="/media/images/icon_back_24.svg" />
                    </a>
                </li>
                <li class="mlr_auto mocuration_title align_center">
                    <slot name="mocurationtitle"> </slot>
                </li>
            </ul>
        </nav>
    </header>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'MoCurationHeader',
  emits: ['clickpre'],
  data () {
    return {};
  },

  mounted () {},

  methods: {
    onClickPre () {
      this.$emit('clickpre');
    }
  }
});
</script>
<style scoped src="@/assets/css/curation/mocurationheader.css"></style>
